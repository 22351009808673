<template>
  <v-row align="center" justify="center" no-gutters class="mt-12">
    <v-col cols="12" class="mb-6">
      <h1 class="text-center title--text font-weight-bold text-h4">
        Escribe tus datos para poder contactarte
      </h1>
    </v-col>

    <v-col cols="11" md="8" xl="6" lg="6">
      <formulario-contacto />
    </v-col>
  </v-row>
</template>

<script>
import FormularioContacto from '@/components/core/formularioContacto.vue';

export default {
  name: 'contacto',
  components: {FormularioContacto},
  props: {},
};
</script>

<style scoped></style>
